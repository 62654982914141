export const imageData = {
  logo: require("../assets/image/logoMain.png"),
  logoProfile: require("../assets/image/logoProfiledefaultMenu.png"),
  logoCommentHeart: require("../assets/image/icoCommentheartOn.png"),
  lockLogo: require("../assets/image/lockLogo.png"),
  imgSearchnone: require("../assets/image/imgSearchnone.png"),
};

export const iconData = {
  iconHitsSm: require("../assets/icon/icoHitsSm.png"),
  icoCommentSm: require("../assets/icon/icoCommentSm.png"),
  icoMindSm: require("../assets/icon/icoMindSm.png"),
  icoCommentreply: require("../assets/icon/icoCommentreply.png"),
  icoCommentno: require("../assets/icon/icoCommentno.png"),
  icoSearch: require("../assets/icon/icoSearch.png"),
  icoBack: require("../assets/icon/icoBack.png"),
};

export const lottieData = {
  loading: require("../assets/animation/loading.json"),
};

export const landingimageData = {
  landingImgHeader: require("../assets/landingimage/landingImgHeader.png"),
  landingImgFooter: require("../assets/landingimage/landingImgFooter.png"),
  landingImgMain: require("../assets/landingimage/landingImgMain.png"),
  landingApp: require("../assets/landingimage/landingApp.png"),
  landingGoogle: require("../assets/landingimage/landingGoogle.png"),
  landingPc: require("../assets/landingimage/landingPc.png"),
  landingLine: require("../assets/landingimage/landingLine.png"),
  landingImg01: require("../assets/landingimage/landingImg01.png"),
  landingImg02: require("../assets/landingimage/landingImg02.png"),
  landingImg03: require("../assets/landingimage/landingImg03.png"),
  landingImg04: require("../assets/landingimage/landingImg04.png"),
  landingImg05: require("../assets/landingimage/landingImg05.png"),
  landingIco01: require("../assets/landingimage/landingIco01.png"),
  landingIco02: require("../assets/landingimage/landingIco02.png"),
  landingIco03: require("../assets/landingimage/landingIco03.png"),
  landingIco04: require("../assets/landingimage/landingIco04.png"),
  landingIco05: require("../assets/landingimage/landingIco05.png"),
  landingIco06: require("../assets/landingimage/landingIco06.png"),
  landingIco07: require("../assets/landingimage/landingIco07.png"),
  landingIco08: require("../assets/landingimage/landingIco08.png"),
  landingIcoFooter: require("../assets/landingimage/landingIcoFooter.png"),
  maskGroup: require("../assets/landingimage/maskGroup.png"),
  maskGroup03: require("../assets/landingimage/maskGroup03.png"),
};

import React, { memo, useEffect, useMemo } from "react";
import { imageData } from "../../constants/imageData";

type Props = {
  className?: string | undefined;
  src?: string | undefined;
};

function CustomImage({ className, src }: Props) {
  useEffect(() => {
    cache();
  }, [src]);

  const cache = async () => {
    if (src) {
      new Promise((resolve, reject) => {
        const image = new Image();
        image.src = src;
        image.onload = resolve;
        image.onerror = reject;
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  return (
    <img
      src={src ?? imageData.logoProfile}
      className={className}
      alt="Image"
      onError={(e) => (e.currentTarget.src = imageData.logoProfile)}
    />
  );
}

export default memo(CustomImage);

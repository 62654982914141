import React, { useEffect, useRef, useState } from "react";
import StringUtil from "../../utils/stringUtil";

type Props = {
  content: string;
  className?: string | undefined;
};

function LinkText({ content, className }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    convert();
  }, [content]);

  const convert = () => {
    var newContent = content;
    const urls = StringUtil.textToUrl(content);

    urls.forEach((url) => {
      newContent = newContent.replace(
        url,
        `<a href="${url}" onclick="event.stopPropagation();" target="_blank" style="color: #0000EE">${url}</a>`
      );
    });

    const parser = new DOMParser();
    const html = parser.parseFromString(newContent, "text/html");

    ref.current?.childNodes.forEach((f) => {
      f.remove();
    });
    ref.current?.appendChild(html.body);
  };

  return (
    <div ref={ref} className={className}>
    </div>
  );
}

export default LinkText;

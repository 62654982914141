import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CursorPaginationMeta } from "../../../apis/models/common/cursorPaginationResponse";
import { useRecoilState } from "recoil";
import { postListState } from "../../../states/postAtom";
import { PostService } from "../../../apis/services/post/postService";
import PostListItem from "./components/PostListItem";
import { imageData } from "../../../constants/imageData";

function SearchPage() {
  const targetRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [meta, setMeta] = useState<CursorPaginationMeta>({
    count: 0,
    next: false,
  });
  const [items, setItems] = useRecoilState(postListState);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleObserver = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    const [target] = entries;
    if (target.isIntersecting) {
      // next가 true 이면
      // 현재 item의 마지막 index의 item에 uuid로 api를 다시 호출
      findAll();
    }
  };

  useEffect(() => {
    findAll();
  }, [location.search]);

  useEffect(() => {
    const option: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef, items]);

  const findAll = async () => {
    try {
      if (items.length > 0 && meta.next) {
        const last = items[items.length - 1];
        params.set("pageUuid", last.uuid);
        const response = await PostService.findAll(params.toString());
        if (response.status === "OK") {
          if (response.data.dtoList.length > 0) {
            setItems((prev) => [...prev, ...response.data.dtoList]);
          } else {
            setItems([...response.data.dtoList]);
          }
          setMeta({ ...response.data.meta });
        }
      } else {
        const response = await PostService.findAll(params.toString());
        if (response.status === "OK") {
          setItems([...response.data.dtoList]);
          setMeta({ ...response.data.meta });
        }
      }
    } catch (error) {}
  };

  return (
    <div className="md:py-10 py-6 max-w-[1280px] m-auto">
      <div className="md:mx-6 mx-3 xl:mx-0">
        <div className="bg-white md:rounded-[10px] rounded-[8px] shadow mb-4 md:py-4 py-2 px-6">
          <p className="md:text-[22px] text-[18px] font-semibold">
            총 {meta.count}건
          </p>
        </div>

        {/* 검색결과가 있을 때 */}
        {items.map((item, index) => (
          <PostListItem
            key={index}
            item={item}
            onClick={() => {
              navigate(`/post/${item.uuid}`);
            }}
          />
        ))}

        {/* 검색결과가 없을 때 */}
        {meta.count === 0 && (
          <div className="flex flex-col items-center justify-center mt-[19px] bg-white md:rounded-[10px] rounded-[8px] shadow md:mb-4 py-4 px-6">
            <img src={imageData.imgSearchnone} alt="" />
            <p className="text-[#9e9e9e] text-[16px] my-4">
              검색결과가 없어요.
            </p>
          </div>
        )}

        {items.length > 0 && meta.next && <div ref={targetRef}></div>}
      </div>
    </div>
  );
}

export default SearchPage;

import { Route, Routes } from "react-router-dom";
import Error404 from "../pages/errors/Error404";
import MainPage from "../pages/main/MainPage";
import Layout from "./layout/Layout";
import PostListPage from "../pages/post/list/PostListPage";
import PostDetailPage from "../pages/post/detail/PostDetailPage";
import SearchPage from "../pages/post/list/SearchPage";
import PolicyTerms from "../pages/policy/PolicyTerms";
import PolicyPrivacy from "../pages/policy/PolicyPrivacy";
import LandingPage from "../pages/landing/LandingPage";

function Router() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/community" element={<MainPage />} />
        <Route path="/post" element={<PostListPage />} />
        <Route path="/post/:id" element={<PostDetailPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/policy/terms" element={<PolicyTerms />} />
        <Route path="/policy/privacy" element={<PolicyPrivacy />} />
      </Route>

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default Router;

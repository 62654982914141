import React from 'react'

function Error404() {
  return (
    <div className='h-screen flex justify-center items-center'>
      Error404
    </div>
  )
}

export default Error404

import React, { useEffect, useState } from "react";

type Props = {
  url: string;
  type?: "normal" | "small";
  onClick?: () => void;
};

function LinkPreview({ url, type = "normal", onClick }: Props) {
  const [preview, setPreview] = useState<{
    title: string;
    description: string;
    image: string;
    url: string;
  } | null>(null);

  useEffect(() => {
    get();
  }, [url]);

  const get = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PREVIEW_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          url: url,
        }),
      });

      const jsonData = await response.json();

      if (jsonData.status === "OK") {
        setPreview(jsonData.data);
      }
    } catch (error) {}
  };

  if (!preview) {
    return <></>;
  }

  return (
    <div
      className="overflow-hidden border md:rounded rounded-[8px] shadow cursor-pointer select-none"
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick();
      }}
    >
      {type === "normal" ? (
        <div className="bg-white ">
          <div className="aspect-[16/9] w-full overflow-hidden">
            {preview.image && (
              <img
                className="object-contain w-full h-full md:p-2"
                src={preview.image}
                alt=""
              />
            )}
          </div>
          <div className="flex flex-col gap-2 mt-2 p-2">
            <p className="md:text-lg text-base line-clamp-2">{preview.title}</p>
            <p className="md:text-base text-xs text-gray-400 line-clamp-2">
              {preview.description}
            </p>
          </div>
        </div>
      ) : (
        <div className="w-full aspect-square">
          {preview.image && (
            <img
              className="object-cover w-full h-full "
              src={preview.image}
              alt=""
            />
          )}
        </div>
      )}
    </div>
  );
}

export default LinkPreview;

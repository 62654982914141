import React, { useEffect, useState } from "react";
import { policyService } from "../../apis/services/policy/policyService";

function PolicyPrivacy() {
  const [item, setItem] = useState<string>("");

  useEffect(() => {
    getPolicy();
  }, []);

  const getPolicy = async () => {
    try {
      const response = await policyService.getPolicy("ETC02");
      setItem(response);
    } catch (error) {}
  };

  return (
    <div className="md:py-10 py-6 max-w-[1280px] m-auto">
      <div className="md:mx-6 mx-3 xl:mx-0">
        <div className="bg-white md:rounded-[10px] rounded-[8px] mb-4 md:py-4 py-2 px-6 shadow ">
          <p className="md:text-[22px] text-[18px] font-semibold ">
            개인정보 처리방침 및 수집이용 동의
          </p>
        </div>

        <div dangerouslySetInnerHTML={{ __html: item }}></div>
      </div>
    </div>
  );
}

export default PolicyPrivacy;

import React from "react";
import { imageData } from "../../constants/imageData";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="h-[203px] max-w-[1280px] m-auto">
      <div className="max-w-[1280px] m-auto flex flex-col md:flex-row items-start mb-4 bg-white">
        <div className="mx-6 xl:mx-0 flex-1">
          <img
            src={imageData.logo}
            className="py-6 cursor-pointer md:w-[177px] w-[118px]"
            alt=""
            onClick={() => {
              navigate(`/`);
            }}
          />
        </div>

        <div className="flex-1 mx-6 xl:mx-0 text-left">
          <div>
            <ul className="flex gap-1 justify-start">
              <li className="flex md:mt-[50px] mt-6 text-[14px] font-[#424242]">
                <div className="flex items-center gap-4">
                  <button
                    onClick={() => {
                      navigate("/policy/terms");
                    }}
                  >
                    이용약관
                  </button>
                  <div>|</div>
                  <button
                    onClick={() => {
                      navigate("/policy/privacy");
                    }}
                  >
                    개인정보처리방침
                  </button>
                </div>
              </li>
            </ul>
            <div className="text-[12px] mt-6 font-[#9e9e9e] leading-4 font-light mb-4">
              주식회사 오너마인드 대표이사 : 최현빈 | 주소 : 경기도 성남시
              갈마치로 288번길 14 성남SKV1타워 A-730 사업자등록번호 :
              123-123456-123 | 통신판매업신고번호 : 제 2023-경기-0089호 | 호스팅
              사업자 : 주식회사 오너마인드 주식회사 이메일 :help@ownermind.co.kr
              | 고객센터 : 1811-2280 (평일 9:00~18:00) © ownermind Corp. All
              rights reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

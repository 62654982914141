import { useNavigate } from "react-router-dom";
import { imageData } from "../../constants/imageData";
import SearchBar from "../../pages/post/list/components/search/SearchBar";

function Header() {
  const navigate = useNavigate();

  return (
    <header className="md:h-[96px] h-[55px] shadow-md z-10 fixed bg-white w-screen ">
      <div className="max-w-[1280px] m-auto ">
        <div className="relative flex justify-between mx-6 xl:mx-0">
          <div className="flex justify-start">
            <img
              src={imageData.logo}
              className="md:py-6 py-[10px] cursor-pointer md:w-[177px] w-[118px]"
              alt=""
              onClick={() => {
                navigate(`/`);
              }}
            />
            <div className="cursor-pointer ml-[120px] py-[10px] font-semibold text-[20px] text-center items-center md:flex hidden">
              <span
                onClick={() => {
                  navigate("/community");
                }}
              >
                커뮤니티
              </span>
            </div>
          </div>
          <SearchBar />
        </div>
      </div>
    </header>
  );
}

export default Header;

import React, { useCallback, useEffect, useRef, useState } from "react";
import PostListItem from "./components/PostListItem";
import { PostService } from "../../../apis/services/post/postService";
import { IPostListResponse } from "../../../apis/models/post/list/postListResponse";
import { Link, useNavigate } from "react-router-dom";
import { CursorPaginationMeta } from "../../../apis/models/common/cursorPaginationResponse";
import { useRecoilState } from "recoil";
import { postListState } from "../../../states/postAtom";
import LottieView from "../../../components/loader/LottieView";
import { lottieData } from "../../../constants/imageData";

function PostListPage() {
  const targetRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [meta, setMeta] = useState<CursorPaginationMeta>({
    count: 0,
    next: false,
  });
  const [items, setItems] = useRecoilState(postListState);

  const handleObserver = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    const [target] = entries;
    if (target.isIntersecting) {
      // next가 true 이면
      // 현재 item의 마지막 index의 item에 uuid로 api를 다시 호출
      setTimeout(() => {
        findAll();
      }, 300);
    }
  };

  useEffect(() => {
    findAll();
  }, []);

  useEffect(() => {
    const option: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef, items]);

  const findAll = async () => {
    try {
      if (items.length > 0 && meta.next) {
        const last = items[items.length - 1];
        const response = await PostService.findAll(`pageUuid=${last.uuid}`);
        if (response.status === "OK") {
          setItems((prev) => [...prev, ...response.data.dtoList]);
          setMeta({ ...response.data.meta });
        }
      } else {
        const response = await PostService.findAll("");
        if (response.status === "OK") {
          setItems([...response.data.dtoList]);
          setMeta({ ...response.data.meta });
        }
      }
    } catch (error) {}
  };

  return (
    <div className="md:py-10 py-6 ">
      <div className="md:mx-6 mx-3 xl:mx-0">
        <div className="bg-white md:rounded-[10px] rounded-[8px] mb-4 md:py-4 py-2 px-6 shadow ">
          <p className="md:text-[22px] text-[18px] font-semibold ">커뮤니티</p>
        </div>
        {items.map((item, index) => (
          <PostListItem
            key={index}
            item={item}
            onClick={() => {
              navigate(`/post/${item.uuid}`);
            }}
          />
        ))}
        <div className="flex justify-center">
          <LottieView
            className="w-40 mt-4 mb-4 -ml-4"
            animationData={lottieData.loading}
            loop={true}
          />
        </div>
        {items.length > 0 && <div ref={targetRef}></div>}
      </div>
    </div>
  );
}

export default PostListPage;

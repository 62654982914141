import React from "react";
import { iconData } from "../../../../constants/imageData";
import { IPostListResponse } from "../../../../apis/models/post/list/postListResponse";
import CustomImage from "../../../../components/image/CustomImage";
import { DateUtil } from "../../../../utils/dateUtil";
import LinkPreview from "../../../../components/preview/LinkPreview";
import StringUtil from "../../../../utils/stringUtil";
import { validateUtil } from "../../../../utils/validateUtil";

type Props = {
  item: IPostListResponse;
  onClick?: () => void;
};

function PostListItem({ item, onClick }: Props) {
  const convertTextToUrls = (text?: string) => {
    const urls = StringUtil.textToUrl(text);
    if (urls.length > 0) {
      return <LinkPreview url={urls[0]} type="small" />;
    }
    return <></>;
  };

  if (item === null) {
    return <></>;
  }

  return (
    <div onClick={onClick} className="cursor-pointer">
      <div>
        <div className="bg-white md:rounded-[10px] rounded-[8px] shadow md:mb-4 mb-2 md:py-6 py-4 px-6">
          {/* Body */}
          <div>
            <div className="flex items-end justify-between">
              <span className="postlist-colortitle">{item.community.name}</span>
              <p className="text-[12px] text-[#bdbdbd]">
                {DateUtil.timeago(item.postDatetime)}
              </p>
            </div>
            <p className="my-2 font-semibold text-[#212121] md:text-[18px] text-[16px]">
              {item.title}
            </p>
            <p className="flex gap-4 text-[#424242] md:text-[16px] text-[14px] font-medium line-clamp-2">
              <div className="flex-grow break-all">
                <div className="line-clamp-2">{item.content}</div>
              </div>

              <div className="flex justify-end shrink-0">
                <div>
                  {validateUtil.isUrl(item.content) ? (
                    <div className="relative rounded-[7px] w-[65px] h-[65px] overflow-hidden">
                      {convertTextToUrls(item.content)}
                    </div>
                  ) : (
                    <>
                      {item.files.length > 0 && (
                        <div className="relative rounded-[7px] md:w-[65px] w-[60px] md:h-[65px] h-[60px] overflow-hidden">
                          <CustomImage src={item.files[0].imageUrl} />
                          {item.files.length > 1 && (
                            <div className="absolute top-0.5 right-1 flex items-center justify-center bg-black/30 w-[29px] h-[18px] rounded-[20px]">
                              <p className="text-white md:text-[12px] text-[10px] font-light">
                                +{item.files.length - 1}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </p>
            <div className="md:border-none border-solid border-[1px] border-[#eeeeee] my-3">
              <span></span>
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-between md:mt-6 mt-2">
            <div>
              <div className="flex gap-2">
                <div className="w-6 ">
                  <CustomImage
                    className="rounded-full aspect-square"
                    src={item.user.profile.imageUrl}
                  />
                </div>
                <div>
                  <p className="text-[#bdbdbd] text-[14px]">
                    {item.user.nickname}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <ul className="flex gap-2">
                {/* 조회수 */}
                <li className="flex items-center gap-1">
                  <span>
                    <img src={iconData.iconHitsSm} alt="" />
                  </span>
                  <p className="text-sm">{item.hit}</p>
                </li>

                {/* 댓글수 */}
                <li className="flex items-center gap-1">
                  <span>
                    <img src={iconData.icoCommentSm} alt="" />
                  </span>
                  <p className="text-sm">{item.reply}</p>
                </li>

                {/* 좋아요수 */}
                <li className="flex items-center gap-1">
                  <span>
                    <img src={iconData.icoMindSm} alt="" />
                  </span>
                  <p className="text-sm">{item.heart.count}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostListItem;

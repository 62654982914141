export const validateUtil = {
  /**
   * Url 유효성 체크
   * @param value
   * @returns
   */
  isUrl: (value?: string) => {
    if (value && typeof value === "string") {
      const regex =
        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      return regex.test(value);
    }
    return false;
  },
};

import { Get } from "../..";
import { ApiResponse } from "../../models/common/apiResponse";
import { CursorPaginationResponse } from "../../models/common/cursorPaginationResponse";
import { IPostCommentsResponse } from "../../models/post/detail/postCommentsResponse";
import { IPostDetailResponse } from "../../models/post/detail/postDetailResponse";
import { IPostListResponse } from "../../models/post/list/postListResponse";

export const PostService = {
  /**
   * Post 전체 조회
   * @returns
   */
  findAll: async (
    params: string
  ): Promise<ApiResponse<CursorPaginationResponse<IPostListResponse[]>>> => {
    return Get(`/web/api/post?${params}`);
  },
  /**
   * Post 단건 조회
   * @param id
   * @returns
   */
  findById: async (id: string): Promise<ApiResponse<IPostDetailResponse>> => {
    return Get(`/web/api/post/${id}`);
  },

  findByReply: async (
    id: string
  ): Promise<ApiResponse<Array<IPostCommentsResponse>>> => {
    return Get(`/web/api/post/${id}/reply`);
  },
};

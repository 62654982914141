import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { iconData } from "../../../../../constants/imageData";
import { useLocation, useNavigate } from "react-router-dom";

function SearchBar() {
  const [text, setText] = useState("");
  const [expend, setExpend] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  params.get("keyword");

  const pressBackButton = () => {
    setExpend(false);
  };

  const pressSearchButton = () => {
    if (text) {
      navigate("/search?keyword=" + encodeURIComponent(text));
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value);
  };

  const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.isComposing) return;
    if (event.key === "Enter") {
      pressSearchButton();
    }
  };

  const pressExpendButton = () => {
    setExpend(true);
  };

  useEffect(() => {
    // pathname("/search")이 "/search"가 아닐때 setText(텍스트 초기화)
    if (location.pathname !== "/search") {
      setText("");
      setExpend(false);
    }
  }, [location]);

  return (
    <div>
      {/* 검색창이 열렸을때 */}
      {expend && (
        <div className="absolute top-0 bottom-0 left-0 right-0 md:relative">
          <div className="relative md:h-auto h-full w-full flex justify-end items-center mx-auto mt-0 md:mt-[28px] bg-white border-b-2 border-[#2D9CDB]">
            {/* 뒤로가기 버튼 */}
            <button type="button" onClick={pressBackButton}>
              <img src={iconData.icoBack} alt="" />
            </button>

            {/* 검색창 */}
            <input
              className="bg-transparent h-10 w-full px-2 text-[16px] xl:mx-0 focus:outline-none"
              type="search"
              name="search"
              value={text}
              placeholder="검색어를 입력하세요"
              onChange={onChange}
              onKeyDown={handleEnter}
            />

            {/* 검색버튼 */}
            <button type="button" className="pr-4 " onClick={pressSearchButton}>
              <img src={iconData.icoSearch} alt="" className="w-[24px]" />
            </button>
          </div>
        </div>
      )}

      {/* 검색창이 닫혔을때 */}
      {!expend && (
        <div className="relative flex items-center justify-end mx-auto mt-2 md:mt-[28px] rounded-[8px] h-10">
          <button type="button" className="right-4" onClick={pressExpendButton}>
            <img src={iconData.icoSearch} alt="" className="w-[24px]" />
          </button>
        </div>
      )}
    </div>
  );
}

export default SearchBar;

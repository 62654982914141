import { useNavigate } from "react-router-dom";
import { constants } from "../../constants/constants";
import { landingimageData } from "../../constants/imageData";

function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="">
      {/* 첫번째 메인배너 영역 */}
      <div className="relative md:h-[770px] h-[650px]">
        <img
          src={landingimageData.landingImgHeader}
          alt=""
          className="absolute top-0 bottom-0 object-cover w-full h-full "
        />
        <div className="max-w-[1280px] m-auto">
          <div className="flex md:flex-row flex-col md:translate-x-6 translate-x-0 md:translate-y-0">
            <div>
              <div className="md:mt-[140px] mt-[48px] text-white md:text-[50px] text-[20px] md:text-start text-center font-bold mb-6">
                오너들의 커뮤니티
                <br />
                혼자 고민하는 오너들을
                <br />
                위한 소통앱, 오너십!
              </div>
              <div className="text-[18px] font-normal text-white mb-[70px] md:block hidden">
                오너들을 위한 커뮤니티부터 오너들에게 딱 맞는 콘텐츠까지!
                <br />
                오너들의 필요한 모든 것 지금, 오너십에서 만나보세요.
              </div>
              <div>
                <div className="flex flex-row md:justify-start justify-center">
                  {/* Google Play */}
                  <a href={constants.androidStoreUrl} target="blank">
                    <img
                      src={landingimageData.landingGoogle}
                      alt=""
                      className="md:mt-6 mt-2 mr-4 md:w-[155px] w-[95px]"
                    />
                  </a>

                  {/* App Store */}
                  <a href={constants.iosStoreUrl} target="blank">
                    <img
                      src={landingimageData.landingApp}
                      alt=""
                      className="md:mt-6 mt-2 mr-4 md:w-[155px] w-[95px]"
                    />
                  </a>

                  {/* PC */}
                  <img
                    src={landingimageData.landingPc}
                    alt=""
                    className="md:mt-6 mt-2 mr-4 cursor-pointer md:w-[155px] w-[95px]"
                    onClick={() => {
                      navigate("/community");
                    }}
                  />
                </div>
              </div>
            </div>
            {/* 핸드폰 목업 이미지 */}
            <div className="md:ml-[155px] md:mt-8 mt-8 flex md:justify-end justify-center">
              <img
                src={landingimageData.landingImgMain}
                alt=""
                className="md:w-[590px] w-[350px] md:h-[680px] h-[380px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* 두번째 배너 - 커뮤니티 */}
      <div className="relative md:h-[800px] h-[390px] bg-white">
        <div className="max-w-[1280px] m-auto">
          <div className="text-center">
            <div className="font-semibold md:text-[36px] text-[20px] md:pt-[80px] pt-[32px]">
              오너들의 커뮤니티
              <br />
              고민하는 오너들을 위한 소통앱
            </div>
            <div className="md:mt-6 mt-[18px] mb-4">
              <span className="mr-4 md:text-[16px] text-[12px] text-[#bdbdbd]">
                메인
              </span>
              <span className="mr-4 md:text-[16px] text-[12px] text-white bg-[#6a80f8] w-auto h-auto rounded-[30px] px-2 py-1">
                커뮤니티
              </span>
              <span className="mr-4 md:text-[16px] text-[12px] text-[#bdbdbd]">
                전체
              </span>
            </div>
            <div>
              <div className="bg-[#6a80f8] left-0 md:h-[60px] h-[24px] right-0 md:mt-[330px] mt-[150px] absolute"></div>
              <div className="relative bottom-0 flex justify-center">
                <img
                  src={landingimageData.landingImg01}
                  alt=""
                  className="md:h-[550px] h-[260px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 정책정보 배너 */}
      <div className="relative md:h-[750px] h-[550px] bg-[#F0F2FE]">
        <div className="md:flex h-[1050px] max-w-[1280px] m-auto ">
          <div className="md:pl-20 md:pr-[220px] md:text-left text-center">
            <div className="text-[#6a80f8] md:text-[20px] text-[12px] font-semibold md:pt-[200px] pt-[32px] ">
              정책 정보
            </div>

            <div className="text-[#424242] md:text-[36px] text-[20px] font-bold leading-tight mb-6">
              편리한 맞춤형
              <br />
              정책 정보
            </div>

            <div className="text-[18px] text-[#616161] leading-[24px] mb-[48px] md:block hidden">
              오너님의 기업을 분석해서
              <br />
              필요한 정책정보를 맞춤형으로 보여드려요!
            </div>

            <div className="flex flex-row md:justify-start justify-center ">
              <div className="flex flex-col items-center mr-[32px]">
                <img
                  src={landingimageData.landingIco01}
                  alt=""
                  className="md:mb-4 mb-2 md:h-[70px] h-[42px] "
                />
                <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium">
                  정책정보
                </span>
              </div>

              <div className="flex flex-col items-center mr-[32px]">
                <img
                  src={landingimageData.landingIco02}
                  alt=""
                  className="md:mb-4 mb-2 md:h-[70px] h-[42px]"
                />
                <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium">
                  행사
                </span>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src={landingimageData.landingIco03}
                  alt=""
                  className="md:mb-4 mb-2 md:h-[70px] h-[42px]"
                />
                <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium">
                  뉴스
                </span>
              </div>
            </div>
          </div>
          <div className=" flex justify-center">
            <img
              src={landingimageData.maskGroup}
              alt=""
              className="md:pt-[100px] absolute md:h-[770px] h-[390px]"
            />
            <div className="flex justify-center">
              <img
                src={landingimageData.landingImg02}
                alt=""
                className="md:pt-[100px] pt-[16px] md:pl-[100px] md:h-[760px] h-[360px] relative"
              />
            </div>
          </div>
        </div>
      </div>

      {/* 기업홍보 배너 */}
      <div className="relative md:h-[750px] h-[550px] bg-[#F0F2FE]">
        <div className="relative h-full m-auto bg-white md:rounded-tl-[250px]">
          <div className="md:flex h-[1050px] max-w-[1280px] m-auto md:justify-end justify-center">
            <div>
              <img
                src={landingimageData.maskGroup}
                alt=""
                className="pt-[100px] absolute md:block hidden transform scale-x-[-1]"
              />
              <div className="">
                <img
                  src={landingimageData.landingImg03}
                  alt=""
                  className="md:pt-[100px] pt-[32px] md:pl-[100px] relative md:h-[760px] h-[350px] md:block hidden "
                />
              </div>
            </div>
            <div className="md:pr-20 md:pl-[220px] md:text-right text-center justify-center">
              <div className="text-[#6a80f8] md:text-[20px] text-[12px] font-semibold md:pt-[200px] pt-8">
                기업홍보
              </div>

              <div className="text-[#424242] md:text-[36px] text-[20px] font-bold leading-tight mb-6">
                서비스를 알리고
                <br />
                소개받을 수 있는 기회
              </div>

              <div className="text-[18px] text-[#616161] leading-[24px] mb-[48px] md:block hidden">
                오너님의 기업을 홍보하고
                <br />
                관심있는 서비스를 찾아보세요!
              </div>

              <div className="flex flex-row md:justify-end justify-center">
                <div className="flex flex-col items-center mr-[32px]">
                  <img
                    src={landingimageData.landingIco04}
                    alt=""
                    className="md:mb-4 mb-2 md:h-[70px] h-[42px]"
                  />
                  <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium">
                    소개해요
                  </span>
                </div>

                <div className="flex flex-col items-center">
                  <img
                    src={landingimageData.landingIco05}
                    alt=""
                    className="md:mb-4 mb-2  md:h-[70px] h-[42px]"
                  />
                  <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium">
                    찾아요
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <img
                src={landingimageData.maskGroup}
                alt=""
                className="md:pt-[100px] absolute md:h-[770px] h-[390px] md:hidden block transform scale-x-[-1]"
              />
              <img
                src={landingimageData.landingImg03}
                alt=""
                className="md:pt-[100px] pt-[16px] md:pl-[100px] relative md:h-[760px] h-[360px] md:hidden block"
              />
            </div>
          </div>
        </div>
      </div>

      {/* 전문가 상담 배너 */}
      <div className="relative md:h-[750px] h-[550px] bg-white">
        <div className="relative h-full m-auto bg-[#eaf2fd] md:rounded-tr-[250px] md:rounded-bl-[250px]">
          <div className="md:flex h-[1050px] max-w-[1280px] m-auto ">
            <div className="md:pl-20 md:pr-[220px] md:text-left text-center">
              <div className="text-[#6a80f8] md:text-[20px] text-[12px] font-semibold md:pt-[200px] pt-[32px] ">
                전문가 상담
              </div>

              <div className="text-[#424242] md:text-[36px] text-[20px] font-bold leading-tight mb-6">
                전문가에게 구하는
                <br />
                똑똑한 자문
              </div>

              <div className="text-[18px] text-[#616161] leading-[24px] mb-[48px] md:block hidden">
                사업 운영에 대해 궁금한 부분을
                <br />
                전문가에게 무료로 상담을 받아보세요!
              </div>

              <div className="flex flex-row md:justify-start justify-center">
                <div className="flex flex-col items-center mr-[32px]">
                  <img
                    src={landingimageData.landingIco06}
                    alt=""
                    className="md:mb-4 mb-2 md:h-[70px] h-[42px]"
                  />
                  <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium ">
                    세무
                  </span>
                </div>

                <div className="flex flex-col items-center mr-[32px]">
                  <img
                    src={landingimageData.landingIco07}
                    alt=""
                    className="md:mb-4 mb-2  md:h-[70px] h-[42px]"
                  />
                  <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium">
                    기업인증
                  </span>
                </div>

                <div className="flex flex-col items-center">
                  <img
                    src={landingimageData.landingIco08}
                    alt=""
                    className="md:mb-4 mb-2  md:h-[70px] h-[42px]"
                  />
                  <span className="md:text-[18px] text-[12px] text-[#212121] md:font-semibold font-medium">
                    전문가 tip
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <img
                src={landingimageData.maskGroup03}
                alt=""
                className="md:pt-[80px] mt-[16px] absolute md:h-[770px] h-[390px] "
              />
              <div className="flex justify-center ">
                <img
                  src={landingimageData.landingImg04}
                  alt=""
                  className="md:pt-[100px] pt-[16px] md:pl-[100px] md:h-[760px] h-[360px] relative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 오너몰 배너 */}
      <div className="relative md:h-[900px] h-[500px] bg-white">
        <div className="max-w-[1280px] m-auto">
          <div className="text-center">
            <div className="md:text-[36px] text-[20px] font-semibold md:pt-[80px] pt-8">
              오너십만의 편리한
              <br />
              후불 결제 서비스 오너몰!
            </div>
            <div className="md:mt-6 mt-4 mb-4 font-semibold">
              <span className="mr-4 md:text-[16px] text-[12px] text-[#6a80f8] bg-[#6a80f8] bg-opacity-10 w-auto h-auto rounded-[30px] px-2 py-1">
                꽃배달
              </span>
              <span className="mr-4 md:text-[16px] text-[12px] text-[#6a80f8] bg-[#6a80f8] bg-opacity-10 w-auto h-auto rounded-[30px] px-2 py-1">
                구매대행
              </span>
              <span className="md:mr-4 mr-0 md:text-[16px] text-[12px] text-[#6a80f8] bg-[#6a80f8] bg-opacity-10 w-auto h-auto rounded-[30px] px-2 py-1">
                선물하기
              </span>
            </div>

            <div className="flex justify-center">
              <img
                src={landingimageData.landingLine}
                alt=""
                className="w-full left-0 right-0 absolute mt-[200px]"
              />
              <img
                src={landingimageData.landingImg05}
                alt=""
                className="relative md:left-[45px] left-[20px] md:-mt-3 mt-4 md:h-[660px] h-[320px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Footer Banner */}
      <div>
        <div className="relative md:h-[260px] h-[130px]">
          <img
            src={landingimageData.landingImgFooter}
            alt=""
            className="absolute top-0 object-cover w-full h-full"
          />
          <div className="max-w-[1280px] m-auto md:pl-20 pl-6 md:translate-x-6 translate-x-0 md:translate-y-0">
            <div className="md:text-[25px] text-[18px] text-white font-semibold md:pt-[40px] pt-6 mb-2">
              예비 오너님들도
              <br />
              오너십을 사용하실 수 있습니다.
            </div>
            <div className="text-[20px] text-white absolute md:block hidden">
              오너들을 위한 커뮤니티부터 오너들에게 딱 맞는 콘텐츠까지!
            </div>

            <div className="flex flex-row absolute md:pt-6">
              {/* Google Play */}
              <a href={constants.androidStoreUrl} target="blank">
                <img
                  src={landingimageData.landingGoogle}
                  alt=""
                  className="md:mt-6 mr-4 md:w-[155px] w-[95px]"
                />
              </a>

              {/* App Store */}
              <a href={constants.iosStoreUrl} target="blank">
                <img
                  src={landingimageData.landingApp}
                  alt=""
                  className="md:mt-6 mr-4 md:w-[155px] w-[95px]"
                />
              </a>

              {/* PC */}
              <img
                src={landingimageData.landingPc}
                alt=""
                className="md:w-[155px] w-[95px] mt-6 cursor-pointer md:block hidden"
                onClick={() => {
                  navigate("/community");
                }}
              />
            </div>
            <div className="absolute top-5 md:right-20 right-10">
              <img
                src={landingimageData.landingIcoFooter}
                alt=""
                className="md:h-[240px] h-[110px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;

/**
 * 문자열 Utility
 */
const StringUtil = {
  /**
   * 입력받은 문자를 전화번호, 핸드폰 번호에 맞게 변환하여 응답.
   * @param value 입력 데이터
   * @param separator 구분 기호 (option)
   * @returns
   */
  convertStringToPhoneNumberFormat: (
    value?: string,
    separator: string = "-"
  ) => {
    let s = separator;
    if (value && typeof value === "string") {
      return value.replace(
        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})(\d{3,4})(\d{4})$/,
        `$1${s}$2${s}$3`
      );
    }
    return "";
  },
  /**
   * 입력받은 문자가 숫자인 것만 응답.
   * @param value 입력 데이터
   * @returns
   */
  extractOnlyNumbers: (value?: string) => {
    if (value && typeof value === "string") {
      return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    }
    return "";
  },
  /**
   * 문자열 안에서 일치되는 모든 pattern을 변경.
   * @param value 
   * @param pattern 
   * @param replacement 
   * @returns 
   */
  replaceAll: (value: string, pattern: string, replacement: string) => {
    return value.split(pattern).join(replacement);
  },
  /**
   * 문자열을 입력받아 url들을 찾아서 응답.
   * @param value 
   * @returns 
   */
  textToUrl: (value?: string): string[] => {
    if (value && typeof value === "string") {
      const urlRegex = /(http?|https):\/\/[^\s/$.?#].[^\s]*/gi;
      const urls = value.match(urlRegex);
      return urls || [];
    }
    return [];
  },
};

export default StringUtil;

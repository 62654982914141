import React from "react";
import PostListPage from "../post/list/PostListPage";

function MainPage() {
  return (
    <div className="max-w-[1280px] m-auto">
      <PostListPage />
    </div>
  );
}

export default MainPage;

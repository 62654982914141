import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IPostDetailResponse } from "../../../apis/models/post/detail/postDetailResponse";
import { PostService } from "../../../apis/services/post/postService";
import CustomImage from "../../../components/image/CustomImage";
import { iconData, imageData } from "../../../constants/imageData";
import { IPostCommentsResponse } from "../../../apis/models/post/detail/postCommentsResponse";
import { DateUtil } from "../../../utils/dateUtil";
import { IPostReply } from "../../../apis/models/post/postReply";
import PostCommentItem from "./components/PostCommentItem";
import LinkPreview from "../../../components/preview/LinkPreview";
import StringUtil from "../../../utils/stringUtil";
import LinkText from "../../../components/preview/LinkText";

function PosDetailPage() {
  const { id } = useParams();
  const [item, setItem] = useState<IPostDetailResponse | null>(null);
  const [items, setItems] = useState<IPostCommentsResponse[]>([]);
  const [subItem, setSubItems] = useState<IPostReply[]>([]);

  console.log(id);

  useEffect(() => {
    if (id) {
      findById();
      findByReply();
    }
  }, [id]);

  const findById = async () => {
    try {
      const response = await PostService.findById(id!);

      if (response.status === "OK") {
        setItem(response.data);
      }
    } catch (error) {}
  };

  const findByReply = async () => {
    try {
      const response = await PostService.findByReply(id!);

      if (response.status === "OK") {
        setItems(response.data);
      }
    } catch (error) {}
  };

  const convertTextToUrls = (text?: string) => {
    const urls = StringUtil.textToUrl(text);
    if (urls.length > 0) {
      return (
        <div className="md:w-[50%] w-full">
          <LinkPreview url={urls[0]} type="normal" />
        </div>
      );
    }
    return <></>;
  };

  if (item === null) {
    return <></>;
  }

  return (
    <div className="md:py-10 py-6 max-w-[1280px] m-auto">
      <div>
        <div className="md:mx-6 mx-3 xl:mx-0">
          <div className="bg-white md:rounded-[10px] rounded-[8px] shadow mb-4 md:py-4 py-2 px-6 break-words">
            {/* Header */}
            <div>
              <div>
                <span className="postlist-colortitle">
                  {item.community.name}
                </span>
              </div>
              <p className="my-2 postitem-title">{item.title}</p>

              <div>
                <div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <CustomImage
                        className="rounded-full aspect-square w-[35px]"
                        src={item.user.profile.imageUrl}
                      />

                      <span className="text-[#616161] text-[14px] flex justify-between">
                        {item.user.nickname}
                      </span>
                    </div>
                    <p className="text-[14px] text-[#bdbdbd]">
                      {DateUtil.timeago(item.postDatetime)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Body */}
            <div>
              <div className="border-solid border-[1px] border-[#eeeeee] md:mb-6 mb-4 mt-[7px]">
                <span></span>
              </div>
              <div>
                <div className="mb-4 postitem-title2">
                  <LinkText content={item.content} />
                </div>
                <div>{convertTextToUrls(item.content)}</div>

                <div className="mt-2">
                  {item.files.map((item, index) => (
                    <CustomImage src={item.imageUrl} className="mb-4 " />
                  ))}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between mt-6">
                  <div className="flex items-center">
                    <img src={imageData.logoCommentHeart} alt="" />

                    <span className="flex items-center text-[12px] font-semibold text-[#2d9cdb] ">
                      {item.heart.count}
                    </span>
                  </div>

                  <span className="flex items-center justify-between text-[14px] text-[#bdbdbd]">
                    조회 {item.hit}
                  </span>
                </div>
              </div>
            </div>

            {/* footer */}
            <div className="border-solid border-[1px] border-[#eeeeee] mb-4 mt-[20px]">
              <span></span>
            </div>

            <div>
              <div>
                <p className="text-[16px] font-semibold mb-6">
                  답변 {item.reply}
                </p>
              </div>

              <div>
                <div>
                  {/* 댓글이 없을 때 */}
                  {items.length === 0 && (
                    <div className="flex flex-col items-center justify-center">
                      <img src={iconData.icoCommentno} alt="" />
                      <p className="text-[#9e9e9e] text-[16px] my-4">
                        아직 댓글이 없어요
                      </p>
                    </div>
                  )}

                  {/* 댓글이 있을 때 */}
                  {items.map((item, index) => (
                    <div key={index}>
                      <PostCommentItem key={index} item={item} />

                      {/* 대댓글 */}
                      {item.subReply.map((subItem, subIndex) => (
                        <PostCommentItem key={subIndex} item={subItem} depth />
                      ))}
                      {items.length - 1 !== index && (
                        <div className="border-solid border-[1px] border-[#eeeeee] my-4">
                          <span></span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PosDetailPage;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import ScrollToTop from "./routes/ScrollToTop";
import Router from "./routes";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <ScrollToTop />
        <Router />
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;

import React from "react";
import { IPostReply } from "../../../../apis/models/post/postReply";
import { iconData, imageData } from "../../../../constants/imageData";
import CustomImage from "../../../../components/image/CustomImage";
import { DateUtil } from "../../../../utils/dateUtil";
import StringUtil from "../../../../utils/stringUtil";
import LinkPreview from "../../../../components/preview/LinkPreview";
import LinkText from "../../../../components/preview/LinkText";

type Props = {
  item: IPostReply;
  depth?: boolean;
};

function PostCommentItem({ item, depth = false }: Props) {
  const convertTextToUrls = (text?: string) => {
    const urls = StringUtil.textToUrl(text);
    if (urls.length > 0) {
      return (
        <div className="md:w-[50%] w-full mb-2">
          <LinkPreview url={urls[0]} type="normal" />
        </div>
      );
    }
    return <></>;
  };

  if (item === null) {
    return <></>;
  }

  return (
    <div className="break-words">
      <div className="flex justify-between ">
        <div className="flex">
          {depth && (
            <div className="flex-shrink-0 mr-1">
              <img className="w-[24px]" src={iconData.icoCommentreply} alt="" />
            </div>
          )}
          <div className="break-all">
            <div className="flex items-start gap-1">
              <div className="w-[35px]">
                <CustomImage
                  className="rounded-full aspect-square"
                  src={item.user.profile.imageUrl}
                />
              </div>
              <p className="text-[14px] text-[#616161] ml-2">
                {item.user.nickname}

                {item.modifyFlag && (
                  <span className="items-end text-[12px] text-[#9e9e9e] ml-[3px]">
                    (수정됨)
                  </span>
                )}

                {item.hideFlag && (
                  <span className="items-end text-[12px] text-[#9e9e9e] ml-[3px]">
                    (비밀글)
                  </span>
                )}

                <p className="text-[12px] text-[#bdbdbd] font-semibold">
                  {DateUtil.timeago(item.createdDatetime)}
                </p>
              </p>
            </div>
            <div className="text-[16px] text-[#616161]">
              {item.hideFlag ? (
                <div className="flex items-center my-2 mt-3">
                  <img src={imageData.lockLogo} alt="" />
                  비밀글 입니다.
                </div>
              ) : (
                <div>
                  <p className="my-2 ">
                    <LinkText content={item.content} />
                  </p>
                  {convertTextToUrls(item.content)}
                  {item.files.map((item, index) => (
                    <div key={index} className="md:w-[50%] mb-2">
                      <img src={item.imageUrl} alt="" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 좋아요 */}
      <div className="flex justify-end">
        <div className="flex items-center">
          <img src={imageData.logoCommentHeart} alt="" />

          <span className="flex items-center text-[12px] font-semibold text-[#2d9cdb]">
            {item.like?.count}
          </span>
        </div>
      </div>
    </div>
  );
}

export default PostCommentItem;
